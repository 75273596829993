import { OrderDataRow, OrderResponse } from "@type/Order"
import handle404ERROR from "@utils/404handler"
import axios from "axios"
import dayjs from "dayjs"
import { StatusCodes } from "http-status-codes"
import { toast } from "react-toastify"

const _toast = () => toast.error('Слишком большое количество запросов')

async function getCurrentUserOrder(encoded_token: string) {
  const result = await axios({
    method: 'get',
    url: '/api/v1/orders_assembly/current_order',
    headers: { 'Authorization': 'Basic ' + encoded_token }
  })
    .then((response) => {
      if (!handle404ERROR(response.data))
        return response.data as OrderResponse
    })
    .catch((error) => {
      if (!error.response)
        return
      if (!error.response.status)
        return
      if (error.response.status == 204)
        return
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS) {
        _toast()
      }
    })

  return result
}

async function fetchOrder(encoded_token: string, order_id: string) {
  const result = await axios({
    method: 'get',
    url: `/api/v1/orders_assembly/${order_id}`,
    headers: { 'Authorization': 'Basic ' + encoded_token }
  })
    .then((response) => {
      if (!handle404ERROR(response.data))
        return response.data as OrderResponse
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      console.error(error)
      return null
    })

  return result
}

async function fetchOrders(encoded_token: string, selectedFloor: string) {
  const result = await axios({
    method: 'get',
    url: `/api/v1/orders_assembly/${selectedFloor}`,
    headers: { 'Authorization': 'Basic ' + encoded_token }
  })
    .then((response) => {
      if (!handle404ERROR(response.data))
        return response.data as OrderResponse[]
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      console.error(error)
      return []
    })

  return result
}

async function fetchHistory(encoded_token: string) {
  const result = await axios({
    method: 'post',
    url: '/api/v1/orders_assembly/history',
    headers: { 'Authorization': 'Basic ' + encoded_token }
  })
    .then((response) => {
      return response.data as OrderResponse[]
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      console.error(error)
      return []
    })

  return result
}

async function fetchOrderNumber(encoded_token: string, order_id: string) {
  const result = await axios({
    method: 'get',
    url: `/api/v1/orders_assembly/${order_id}/number`,
    headers: { 'Authorization': 'Basic ' + encoded_token }
  })
    .then((response) => {
      if (!handle404ERROR(response.data))
        return response.data as string
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      console.error(error)
      return ''
    })

  return result
}

async function fetchOrderRows(encoded_token: string, order_id: string) {
  const result = await axios({
    method: 'get',
    url: `/api/v1/orders_assembly/${order_id}/rows`,
    headers: { 'Authorization': 'Basic ' + encoded_token }
  })
    .then((response) => {
      if (!handle404ERROR(response.data))
        return response.data as OrderDataRow[]
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      console.error(error)
      return []
    })

  return result
}

async function postRowAssembly(order_id: string, row_id: string, assembledAmount: string, encoded_token: string) {
  const result = await axios({
    method: 'post',
    url: `/api/v1/orders_assembly/${order_id}/rows/${row_id}/end_assembly`,
    headers: { 'Authorization': 'Basic ' + encoded_token },
    data: {
      completed: assembledAmount
    }
  })
    .then((response) => {
      return response.status as StatusCodes.OK
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      console.error(error)
    })

  return result
}

async function postOrderStartAssembly(order_id: string, encoded_token: string) {
  const date = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const result = await axios({
    method: 'post',
    url: `/api/v1/orders_assembly/${order_id}/start_assembly`,
    headers: { 'Authorization': 'Basic ' + encoded_token },
    data: {
      start_time: date
    }
  })
    .then((response) => {
      return response.status as StatusCodes.OK
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS) {
        _toast()
        return StatusCodes.TOO_MANY_REQUESTS
      }
      if (error.response.status == StatusCodes.BAD_REQUEST) { // Заказ уже в работе
        return
      }
    })

  return result
}

async function postOrderAssembly(order_id: string, placeAmount: string, encoded_token: string) {
  const date = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const result = await axios({
    method: 'post',
    url: `/api/v1/orders_assembly/${order_id}/end_assembly`,
    headers: { 'Authorization': 'Basic ' + encoded_token },
    data: {
      place_count: placeAmount,
      end_time: date
    }
  })
    .then((response) => {
      return response.status as StatusCodes.OK
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      console.error(error)
    })

  return result
}

async function postOrderAssemblyOneC(order_id: string, encoded_token: string) {
  const result = await axios({
    method: 'post',
    url: `/api/v1/orders_assembly/${order_id}/send_to_one_c`,
    headers: { 'Authorization': 'Basic ' + encoded_token },
  })
    .then((response) => {
      return response.status as StatusCodes.OK
    })
    .catch((error) => {
      if (error.response.status == StatusCodes.TOO_MANY_REQUESTS)
        _toast()
      if (error.response.status == StatusCodes.BAD_REQUEST || error.response.status == StatusCodes.METHOD_NOT_ALLOWED)
        toast.error("Не удалось сохранить информацию о заказе на этапе маркироваки, попробуйте ещё раз")
    })

  return result
}

export {
  getCurrentUserOrder,
  fetchOrder,
  fetchOrders,
  fetchHistory,
  fetchOrderNumber,
  fetchOrderRows,
  postRowAssembly,
  postOrderStartAssembly,
  postOrderAssembly,
  postOrderAssemblyOneC,
}